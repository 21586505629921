<template>
<span>
    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Usuários</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="grey darken-2" dark class="mb-1" @click.prevent="atualizaDados">
                <v-icon dark>sync</v-icon>Atualizar
            </v-btn>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field v-model="search" append-icon="search" label="Localizar" outlined></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="loadUsers" :search="search" :rowsPerPageItems="rowsPerPageItems">

            <template v-slot:[`item.usu_status`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.usu_status" @change="onChangeEventHandler(item.usu_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
                <v-tooltip top color="lime white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="lime lighten-1" @click.prevent="detailUser(item)" v-on="on">
                            <v-icon dark class="mdi mdi-view-headline"></v-icon>
                        </v-btn>
                    </template>
                    <span>Detalhes</span>
                </v-tooltip>

                <v-tooltip top color="red white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="red lighten-1" @click.prevent="ordersUser(item)" v-on="on">
                            <v-icon dark class="mdi mdi-cart"></v-icon>
                        </v-btn>
                    </template>
                    <span>Mostrar Pedidos</span>
                </v-tooltip>
            </template>

            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">Voce procurou por "{{ search }}" nenhum item localizado.</v-alert>
            </template>
        </v-data-table>
    </v-card>
</span>
</template>

<script>
import {
    URL_BASE
} from "../../../config/configs";

export default {
    name: "UserComponent",

    created() {
        this.$store.dispatch("loadUsers");
    },
    computed: {
        loadUsers() {
            if (this.$store.state.users.items.data == null) return [];

            return this.$store.state.users.items.data;
        }
    },
    data() {
        return {
            search: "",
            rowsPerPageItems: [
                10,
                20,
                30,
                {
                    text: "$vuetify.dataIterator.rowsPerPageAll",
                    "10": -1
                }
            ],
            headers: [{
                    sortable: false,
                    text: "Tipo",
                    value: "usu_tipo"
                },
                {
                    sortable: false,
                    text: "Nome",
                    value: "usu_nome"
                },
                {
                    sortable: false,
                    text: "CPF",
                    value: "usu_cpf"
                },
                {
                    sortable: false,
                    text: "Email",
                    value: "usu_email"
                },
                {
                    sortable: false,
                    text: "Telefone",
                    value: "usu_fone1"
                },
                {
                    sortable: false,
                    text: "Status",
                    value: "usu_status"
                },
                {
                    text: "Ações",
                    align: "center",
                    sortable: false,
                    value: "acoes"
                }
            ],
            user: [{
                usu_id: "1",
                email: "",
                usu_tipo: "",
                usu_nome: "",
                usu_cpf: "",
                usu_rg: "",
                usu_cnpj: "",
                usu_ie: "",
                usu_prefixo: "",
                usu_fone1: "",
                usu_fone2: "",
                usu_celular: "",
                usu_status: false
            }],
            base_url: URL_BASE
        };
    },
    methods: {
        atualizaDados() {
            this.$store.dispatch("loadUsers");
        },
        detailUser(item) {
            this.$router.push({
                name: "user.detail",
                params: {
                    usu_id: item.usu_id
                }
            });
        },
        ordersUser(item) {
            this.$router.push({
                name: "user.orders",
                params: {
                    usu_id: item.usu_id
                }
            });
        },

        onChangeEventHandler(usu_id, value) {
            this.$store
                .dispatch("updateStatusUser", {
                    usu_id: usu_id,
                    usu_status: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Sucesso",
                        text: "Status do Popup atualizado com sucesso",
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Opssss!",
                        text: "Erro ao atualizar status do Popup",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        }
    }
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}
</style>
